(function ( $ ) {
     $.fn.menuLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var dropdown   = $('.dropdown');
        var nav        = $('#offset');
        var sub        = $('#offset li.hasSub');
        var opened     = false;

        function _init() {
            $( 'a.mobilNav' ).click(function() {
                if(opened === false){
                    opened  = true;
                    _openNavigation();
                }else{
                    opened = false;
                    _closeNavigation();
                }
            });
        }

        function _closeNavigation(){
            $(nav).addClass('close');
            $(objLink).find('i').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');

        }

        function _openNavigation(){
            $(nav).addClass('active').removeClass('close');
            $(objLink).find('i').removeClass('icon-menu').addClass('icon-close');
            $('body').addClass('locked');
            _initSub();
        }

        function _initSub(){
            $( sub ).each(function( index ) {
                $(this).find('span').click(function() {
                    $('#offset li.hasSub').removeClass('isOpen');
                    $(this).closest('.hasSub').addClass('isOpen');
                });
            });
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("body").menuLink();
